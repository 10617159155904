import React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { Helmet } from 'react-helmet'
import Banner from "../components/compare-banner"
import List from "../components/compare-list"
// import SingleContent from "../components/single-column"
import Robots from "../components/robots"
import Gateways from "../components/gateways"
import FreeTrial from '../components/free-trial'
// import ProTabs from "../components/pro-tabs"
import {
  trialContent,
} from "../lib/home-content"

const bannerContent = {
  height: "auto",
  title: "Every feature you need to run your subscription business.",
  text:
    "Billsby is an incredibly powerful and complete solution to run your subscription business - and we've worked really hard to ensure that you'll find every feature you need is included and ready for you to use.",
}

const listContent = {
  bgColor: "",
  content: [
    {
      title:
        "Once you've seen products, plans and cycles you'll never look back.",
      image: require("../images/ppc-header.svg"),
      svg: true,
      list: [
        {
          subtitle: "Easy to get started and ready as you grow ",
          text:
            "Building the strongest possible foundation for your business means being prepared for growth and expansion - which means keeping all of your available subscription options organised. With Billsby's unique plan hierarchy, stay in control.",
        },
        {
          subtitle: "Add new plans anytime ",
          text:
            "Because products, plans and cycles are entirely addressable through the Billsby API, you can add new ones whenever you want with no further configuration or development work required. It makes it easy to run a commercially astute subscription business that changes based on customer needs.",
        },
      ],
    },
    {
      title: "Advanced billing options give you the flexibility you need.",
      image: require("../images/advance.svg"),
      svg: true,
      list: [
        {
          subtitle: "Free trials and setup fees? No problem. ",
          text:
            "Support for free trials and setup fees is built into Billsby so you can get your customers started and charge based on the commercial model that makes sense for your business and your requirements.",
        },
        {
          subtitle:
            "Minimum terms with contract enforcement - included as standard ",
          text:
            "If you need to have minimum terms for your product, you can. We includes support for minimum terms - alongside contract enforcement and early termination fees - as standard, so you can lock customers into your subscription plans and add certainty to your monthly recurring revenue.",
        },
      ],
    },
    {
      title:
        "Easy yet advanced - our add-ons and allowances can build complex plans.",
      image: require("../images/addons-allowances.svg"),
      svg: true,
      list: [
        {
          subtitle: "Create as many add-ons and allowances as you need ",
          text:
            "There's no limit to the plans you can create with unlimited add-ons and allowances. We even support forced add-ons, overage only allowances and linking add-ons and allowances to access control. Everything you could possibly need is a click away.",
        },
        {
          subtitle: "Real-time usage tracking ",
          text:
            "Your developers will love that they can send usage information to Billsby in real time, with no need to create separate usage monitoring systems. We'll keep track and automatically calculate invoices for you at the end of each billing cycle. It's the simplest way to bill for allowances.",
        },
      ],
    },
    {
      title: "Be as generous as Oprah with coupon codes and discounts.",
      image: require("../images/discount-coupons.svg"),
      svg: true,
      list: [
        {
          subtitle: "Create as many discounts as you'd like ",
          text:
            "Support for both flat and percentage discounts applied to either the customers total invoice or their base plan, with your choice of expiry rules and terms mean that you have complete commercial flexibility.",
        },
        {
          subtitle: "Keep control of your promotional spend ",
          text:
            "Set limits, issue unique coupon codes, add end dates to your promotion and withdraw coupons and offers from the market at any time. You should never feel out of control of your own pricing, and we make sure you always know what's going on with each of the offers you provide.",
        },
      ],
    },
    {
      title:
        "Look like you with completely customizable brand identity options.",
      image: require("../images/customize.svg"),
      svg: true,
      list: [
        {
          subtitle: "Your logos, your colors, your lingo ",
          text:
            "Have the flexibility to setup your brand to look exactly how you want it within Billsby. It's completely up to you how your brand is portrayed to clients, and you can change things whenever you'd like.",
        },
        {
          subtitle: "Control beyond checkout ",
          text:
            "Your branding is applied to checkout, account management, emails, invoices, credit notes and more - so every touchpoint feels like a communication from you, rather than from us. It's your brand identity, we're just here to make sure everything works for you as you'd expect.",
        },
      ],
    },
  ],
}

const listContent2 = {
  bgColor: "",
  sectionFeature: true,
  content: [
    {
      title: "Get started with our advanced checkout in seconds.",
      image: require("../images/checkout-payments.svg"),
      svg: true,
      list: [
        {
          subtitle: "Installs in two lines of code ",
          text:
            "You can be taking subscription payments on your website later today - because Billsby Checkout installs in just two lines of code. That even gives you access to our advanced features like custom fields.",
        },
        {
          subtitle: "PCI compliance made simple ",
          text:
            "With Billsby, you never have to store or transmit a credit card number. This significantly reduces your PCI compliance burden and helps you to get started as quickly as possible - so that you can worry about helping your customers, rather than being on the wrong side of the law.",
        },
      ],
    },
    {
      title: "Support for taxation rules world-over included as standard.",
      image: require("../images/salestax-compliance.svg"),
      svg: true,
      list: [
        {
          subtitle: "Complex tax law made simple ",
          text:
            "You can charge whatever taxes you need all over the world - with specialist support for the United States, Canada, Europe, Australia, New Zealand and India - where tax rules are particularly complex.",
        },
        {
          subtitle: "Even get your taxes filed for you ",
          text:
            "Use Billsby alongside your TaxJar account and benefit from having your US sales taxes automatically filed in all 50 states - with no need to file taxes in each jurisdiction yourself. It'll save you hours that you can use to grow your business instead of on annoying paperwork.",
        },
      ],
    },
    {
      title: "Invoices and emails that reduce churn and retain customers.",
      image: require("../images/invoices-emails-header.svg"),
      svg: true,
      list: [
        {
          subtitle: "The biggest change to invoices since carbon paper ",
          text:
            "Our advanced invoices include baked-in customer service and deflection, let you advertise new products and help customers to get back on track once their payments fail. So much more powerful than an old-fashioned PDF.",
        },
        {
          subtitle: "All your transactional emails are ready-to-go ",
          text:
            "Don't waste time building a bunch of transactional emails for your subscription. Just turn on the ones you need from our vast library, and customize the copy and information to suit your brand. It's the easiest way to communicate with your customers and keep them in the loop.",
        },
      ],
    },
    {
      title: "Empower your customers to self-service their accounts.",
      image: require("../images/in-life-account-management.svg"),
      svg: true,
      list: [
        {
          subtitle: "Full self-service functionality ",
          text:
            "Customers can update add-ons and allowances, check their usage, apply coupons, add new plans or update their payment details quickly and easily. Integrating self-service into your website takes just two lines of code.",
        },
        {
          subtitle: "Headless self-service API ",
          text:
            "If you want to do it yourself, you can use our self-service API to build a full stack of self-service functionality into your website without having to build any of the tools, databases or systems for yourself. Or mix and match between both approaches. You're in control, always.",
        },
      ],
    },
    {
      title:
        "Tackle both voluntary and involuntary churn with our advanced tools.",
      image: require("../images/dunning-retention.svg"),
      svg: true,
      list: [
        {
          subtitle: "Dunning done for you ",
          text:
            "Stopping failed payments in their tracks, our dunning engine automatically jumps into action to recover declines - including advanced features like one-click reattempt and automatic account suspension.",
        },
        {
          subtitle:
            "Convince customers to stay - or understand why they're leaving ",
          text:
            "Customers leave - but our retention tools can help you to convince them to stay with proactive offers of support, discounting and deflection tools. And when they do leave, our exit reasons survey will help you to collect the data you need to understand why so you can improve your business and processes.",
        },
      ],
    },
  ],
}

const singleContent = {
  title: "Explore away, there's no credit card required",
  text:
    "We know that sometimes the best way to evaluate a solution is to try it for yourself, so we've made sure that signing up doesn't require a credit card, and our onboarding team are standing by to help with any questions.",
  button: [
    {
      buttonColor: "orange",
      url: "https://app.billsby.com/registration",
      btnText: "Sign up today",
    },
    {
      scheduleButton: true,
      buttonColor: "black",
    },
  ],
}

const singleContent2 = {
  title: "Stop reading this webpage and try Billsby for yourself",
  text:
    "Honestly, this webpage is far less exciting than creating an account and starting a subscription business today. There's even a $5,000 free trial so you've no reason not to get started.",
  button: [
    {
      buttonColor: "orange",
      url: "https://app.billsby.com/registration",
      btnText: "Sign up today",
    },
    {
      scheduleButton: true,
      buttonColor: "black",
    },
  ],
}

const singleContent3 = {
  title: "Get started with Billsby today",
  text:
    "We know that sometimes the best way to evaluate a solution is to try it for yourself, so we've made sure that signing up doesn't require a credit card, and our onboarding team are standing by to help with any questions.",
  button: [
    {
      buttonColor: "orange",
      url: "https://app.billsby.com/registration",
      btnText: "Sign up today",
    },
    {
      scheduleButton: true,
      buttonColor: "black",
    },
  ],
}

const robotContent = {
  bgColor: "white",
  title: "Get the insights you need and hook up with your other tools",
  titleColor: "black",
  block: [
    // {
    //   img: require('../images/icon-valuescore.svg'),
    //   altText: 'BILLSBY VALUE SCORE',
    //   text: 'BILLSBY VALUE SCORE',
    //   url: '/product/reporting/billsby-value-score',
    // },
    {
      img: require("../images/icon-dashboard.svg"),
      altText: "DASHBOARD",
      text: "DASHBOARD",
      url: "/product/reporting/dashboard",
    },
    {
      img: require("../images/icon-reports.svg"),
      altText: "REPORTING AND INSIGHTS",
      text: "REPORTING AND INSIGHTS",
      url: "/product/reporting/reports-and-insight",
    },
    {
      img: require("../images/icon-accounting.svg"),
      altText: "ACCOUNTING",
      text: "ACCOUNTING",
      url: "/product/integrations/accounting",
    },
    {
      img: require("../images/api-relay-race-1.svg"),
      altText: "API AND WEBHOOKS",
      text: "API AND WEBHOOKS",
      url: "/product/integrations/api-and-web-hooks",
    },
    // {
    //   img: require("../images/icon-customerservice.svg"),
    //   altText: "CUSTOMER SERVICE",
    //   text: "CUSTOMER SERVICE",
    //   url: "/product/integrations/customer-service",
    // },
    {
      img: require("../images/feature-tags-1.svg"),
      altText: "FEATURE TAGS",
      text: "FEATURE TAGS",
      url: "/product/integrations/feature-tags",
    },
    // {
    //   img: require("../images/icon-planactions.svg"),
    //   altText: "PLAN ACTIONS",
    //   text: "PLAN ACTIONS",
    //   url: "/product/integrations/planactions",
    // },
    {
      img: require("../images/zapier-switchboard-1.svg"),
      altText: "ZAPIER",
      text: "ZAPIER",
      url: "/product/integrations/zapier",
    },
  ],
}

const gatewaysContent = {
  title: "Native support for a wide variety of payment gateways",
  text:
    "Billsby supports a broad and growing range of payment gateways, so you can choose the processor that's the best fit for your business. Support for other payment methods (like Direct Debit and ACH) is coming soon.",
  block: [
    {
      img: require("../images/adyen.png"),
      svg: true,
      brandName: "Adyen",
    },
    {
      img: require("../images/authorize.net.png"),
      svg: true,
      brandName: "authorize.net",
    },
    {
      img: require("../images/braintree.png"),
      svg: true,
      brandName: "Braintree",
    },
    {
      img: require("../images/checkout.png"),
      svg: true,
      brandName: "checkout.com",
    },
    {
      img: require("../images/ixopay.png"),
      svg: true,
      brandName: "IXOPAY",
    },
    {
      img: require("../images/stripe.png"),
      svg: true,
      brandName: "Stripe",
    },
  ],
}

// const tabsContent = {
//   backgroundColor: "black",
//   title:
//     "Get ready to take your subscription business to the next level with premium add-ons available in your Billsby account",
//   cardLinks: [
//     {
//       url: "/pro/advanced-gateways",
//       title: "Advanced gateway selection",
//     },
//     {
//       url: "/pro/advanced-value-score/",
//       title: "Advanced Value Score",
//     },
//     {
//       url: "/pro/affiliate-scheme/",
//       title: "Affiliate schemes",
//     },
//     {
//       url: "/pro/cardless-trials/",
//       title: "Cardless trial",
//     },
//     {
//       url: "/pro/debt-collection/",
//       title: "Debt collection",
//     },
//     {
//       url: "/pro/membership-cards/",
//       title: "Membership cards",
//     },
//     {
//       url: "/pro/priority-support/",
//       title: "Priority support",
//     },
//     {
//       url: "/pro/quotes-bespoke-plans/",
//       title: "Quotes and bespoke plans",
//     },
//     {
//       url: "/pro/referral-program/",
//       title: "Referral programs",
//     },
//     {
//       url: "/pro/revenue-recognition/",
//       title: "Revenue recognition",
//     },
//     {
//       url: "/pro/whitelabel/",
//       title: "White label",
//     },
//   ],
// }

const features = () => (
  <Layout>
    <SEO
      title="Features | Billsby | Powerful, customizable subscription billing software"
      description="Hundreds of features combine to make Billsby the most powerful, customizable and easy-to-integrate subscription billing and recurring payments management software."
      url="https://www.billsby.com/features"
    />

    <div className="features">
      <Banner content={bannerContent} />
      <List compare={listContent} />
      {/* <SingleContent content={singleContent} /> */}
      <List compare={listContent2} />
      {/* <SingleContent content={singleContent2} /> */}
      <Robots content={robotContent} />
      <Gateways content={gatewaysContent} />
      {/* <ProTabs content={tabsContent} /> */}
      {/* <SingleContent content={singleContent3} /> */}
      <FreeTrial content={trialContent}/>
    </div>
  </Layout>
)

export default features
